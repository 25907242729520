import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import { get, post, put, pots } from './http/http'

const app = createApp(App)

app.config.globalProperties.$post = post
app.config.globalProperties.$get = get
app.config.globalProperties.$put = put
app.config.globalProperties.$pots = pots
app.config.globalProperties.$message = message

app.use(router).use(Antd).mount('#app')