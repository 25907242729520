/*
 * @Descripttion: fileNames
 * @Author: cjtang
 * @Date: 2021-12-15 09:41:57
 * @LastEditors: cjtang
 * @LastEditTime: 2022-03-17 09:33:10
 */
/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import QS from 'qs'
import { message } from 'ant-design-vue'

// 环境配置
const environment = 'dev'// 测试环境:test 生产环境：dev
const baseUrl_test = '/test/'//测试URL(端口是node.js的端口号)
const baseUrl_dev = '/api/'
//const baseUrl_dev = 'https://health-jkb.jkbaohealth.com:9093/health-mall'//生产URL新
//const baseUrl_dev = 'https://server.jkbaohealth.online/api'//生产URL

// 请求超时时间
axios.defaults.timeout = 10000

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8;'


//http request 拦截器
axios.interceptors.request.use(
    config => {
        // config.data = config.data;
        // config.headers = {
        //     'Content-Type': 'application/json;charset=UTF-8;'
        // }
        const token = sessionStorage.getItem('token') || ''
        if (token.length > 0) config.headers.authorization = token
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

//响应拦截器
axios.interceptors.response.use((response) => {
    // hideLoading()
    const { data } = response
    const isObjData = Object.prototype.toString.call(data) === '[object Object]'
    if (isObjData && 'success' in data && !data.success) {
        return Promise.reject(data)
    }
    if (response.headers.authorization) sessionStorage.setItem('token', response.headers.authorization)
    return response;
}, (error) => {
    console.log(error)
    //响应错误
    if (error.response && error.response.status) {
        // ElMessage.error(message);
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params, address) {
    setDomain(address)
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data)
                if (res.data.code == '502') {
                    toLogin()
                    this.$router.push({ path: '/shop', query: { isLogin: "false" } })
                }
            })
            .catch(err => {
                console.log(err)
            })
    })
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, address) {
    setDomain(address)
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data)
                if (res.data.code == '502') {
                    toLogin()
                    this.$router.push({ path: '/shop', query: { isLogin: "false" } })
                }
            })
            .catch(err => {
                console.log(err)
            })
    })
}

export function pots(url, data, address) {
    setDomain(address)
    return new Promise((resolve, reject) => {
        axios.post(url, data)
            .then(res => {
                resolve(res.data)
                if (res.data.code == '502') {
                    toLogin()
                    this.$router.push({ path: '/shop', query: { isLogin: "false" } })
                }
            })
            .catch(err => {
                console.log(err)
            })
    })
}

/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params, address) {
    setDomain(address)
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data)
                if (res.data.code == '502') {
                    toLogin()
                    this.$router.push({ path: '/shop', query: { isLogin: "false" } })
                }
            })
            .catch(err => {
                console.log(err)
            })
    })
}

// 登录过期处理
function toLogin() {
    message.error('登录过期，请重新登录')
    sessionStorage.removeItem('isLogin')
    sessionStorage.removeItem('userInfo')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('isAdmin')
}

// 对域名进行处理
function setDomain(address) {
    if (address) {
        axios.defaults.baseURL = address
    } else {
        axios.defaults.baseURL = environment == 'test' ? baseUrl_test : baseUrl_dev
    }
}