/*
 * @Descripttion: fileNames
 * @Author: cjtang
 * @Date: 2022-02-03 11:32:19
 * @LastEditors: cjtang
 * @LastEditTime: 2022-02-10 13:11:33
 */
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'home'
    },
  },
  {
    path: '/shop',
    name: 'home',
    component: () => import('./views/Home/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/shop/toBuy',
    name: 'toBuy',
    component: () => import('./views/ToBuy/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/shop/OrderForGoods',
    name: 'OrderForGoods',
    component: () => import('./views/OrderForGoods/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/shop/Account',
    name: 'Account',
    component: () => import('./views/Account/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/shop/GoodsDetail',
    name: 'GoodsDetail',
    component: () => import('./views/GoodsDetail/index.vue'),
    meta: {
      requireAuth: true
    },
  },
  {
    path: '/shop/Admin',
    name: 'Admin',
    component: () => import('./views/Admin/index.vue'),
    meta: {
      requireAuth: true
    },
  },
]

export default createRouter({
  history: createWebHashHistory(),
  routes,
})